<template>
  <div>
    <b-modal
      id="modal-add-adjustment-reason"
      cancel-variant="outline-secondary"
      :ok-title="$t('Add')"
      :cancel-title="$t('Close')"
      ok-variant="success"
      centered
      modal-class="modal-success"
      :title="$t('Add Adjustment Reason')"
      @ok="confirmAdd"
      @hidden="resetModal()"
    >
      <!-- form -->
      <validation-observer ref="addAdjustmentReasonModal">
        <b-form
          method="POST"
          class="auth-reset-password-form mt-2"
          @submit.prevent="validationForm"
        >
          <!-- Field: Name -->
          <b-form-group label-for="name">
            <label class="mb-1">{{ $t("Name") }}</label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="adjustmentReason.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Field: is_bonus  -->
          <b-form-group label-for="bonus">
            <label class="mb-1">{{ $t("Is Bonus") }}</label>
            <b-form-radio-group
                id="bonus"
                name="bonus"
                v-model="adjustmentReason.isBonus"
                :options="options"
                value="1"
            ></b-form-radio-group>
<!--            <small class="text-danger">{{ errors[0] }}</small>-->
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BFormRadioGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {},
  data() {
    return {
      adjustmentReason: {
        name: "",
        isBonus: ""
      },
    };
  },
  methods: {
    validationForm() {
      this.$refs.addAdjustmentReasonModal.validate().then((success) => {
        if (success) {
          this.addAdjustmentReason(this.adjustmentReason);
        }
      });
    },
    async addAdjustmentReason(AdjustmentReasonData) {
      await this.$store
        .dispatch("reason/addAdjustmentReason", AdjustmentReasonData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code == "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("refetch-data");
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-add-adjustment-reason");
                  this.resetModal();
                }, "500");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.adjustmentReason = {
        name: "",
      };
    },
    confirmAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    const options = [
      { value: '1', text: 'Có' },
      { value: '2', text: 'Không' }
    ]
    return {
      options
    };
  },
};
</script>